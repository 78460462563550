import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: false,
		originApi:'http://localhost:8000',
		objective:{
      bio:false,
      objItems:[]
    }
  },
  getters: {
  },
  mutations: {
    bio(state, option){
      state.objective.bio = option
    },
    obj1(state, option){
      state.objective.objItems.obj1 = option
    },
    obj2(state, option){
      state.objective.objItems.obj2 = option
    },
    obj3(state, option){
      state.objective.objItems.obj3 = option
    },
    obj4(state, option){
      state.objective.objItems.obj4 = option
    },
    obj5(state, option){
      state.objective.objItems.obj5 = option
    }
  },
  actions: {
  },
  modules: {
  }
})
