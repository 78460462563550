<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-tabs v-model="tab" bg-color="primary">
            <v-tab value="zero"
              >Home
              <v-icon
                v-if="objective.bio"
                class="icon-cehck"
                color="green"
                icon="mdi-check-circle"
              ></v-icon>
            </v-tab>
            <v-tab value="one"
              >Objective One
              <v-icon
                v-if="objective.objItems.obj1"
                class="icon-cehck"
                color="green"
                icon="mdi-check-circle"
              ></v-icon
            ></v-tab>
            <v-tab value="two"
              >Objective Two
              <v-icon
                v-if="objective.objItems.obj2"
                class="icon-cehck"
                color="green"
                icon="mdi-check-circle"
              ></v-icon
            ></v-tab>
            <v-tab value="three"
              >Objective Three
              <v-icon
                v-if="objective.objItems.obj3"
                class="icon-cehck"
                color="green"
                icon="mdi-check-circle"
              ></v-icon
            ></v-tab>
            <v-tab value="four"
              >Objective Four
              <v-icon
                v-if="objective.objItems.obj4"
                class="icon-cehck"
                color="green"
                icon="mdi-check-circle"
              ></v-icon
            ></v-tab>
            <v-tab value="five"
              >Objective Five
              <v-icon
                v-if="objective.objItems.obj5"
                class="icon-cehck"
                color="green"
                icon="mdi-check-circle"
              ></v-icon
            ></v-tab>
          </v-tabs>

          <v-card-text>
            <v-window v-model="tab">
              <v-window-item value="zero">

                <v-sheet width="450" class="biodata">
                  <v-form @submit.prevent>
                    <v-text-field
                      v-model="data.fullname"
                      label="Full Name"
                    ></v-text-field>
                    <v-btn type="submit" block class="mt-2" @click="simpanNama()">Simpan Nama</v-btn>
                  </v-form>
                </v-sheet>

                <v-btn
                  v-if="btnCapturelayar"
                  append-icon="mdi-record"
                  @click="startCapture()"
                >
                  Capture Layar
                </v-btn>
                <v-btn
                  v-if="btnRekamlayar"
                  append-icon="mdi-record"
                  @click="isCapture?startRecord():null"
                >
                  Rekam Layar
                </v-btn>
                <v-btn
                  v-if="btnBerhentirekamlayar"
                  append-icon="mdi-checkbox-blank"
                  @click="isCapture?startRecord():null"
                >
                  Berhenti Rekam Layar
                </v-btn>

                <p class="notes-record">*Diharapkan tidak melakukan refresh page pada saat tersing berlangsung</p>

                <h2 class="preview-recording">Preview</h2>
                <video
                  id="vpreview"
                  class="preview"
                  autoplay
                  style="width: 380px; height: 200px;"
                ></video>
              </v-window-item>

              <v-window-item value="one">
                <v-row>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12">
                        <v-expansion-panels>
                          <v-expansion-panel
                            title="Misi 1 (Klik untuk melihat misi)"
                            text="Anda adalah seorang mahasiswa yang sedang mencari data untuk menyelesaikan tugas akhir mengenai perkembangan di Jakarta.Anda sedang membuka website Satu Data Jakarta dan ingin mencari series dataset “Data Ekspor Impor DKI Jakarta”. Bagaimana Anda melakukannya?"
                          ></v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <v-col cols="12" style="margin-top: 1em;">
                        <div class="text-caption">
                          Dalam skala 1 sampai 10, seberapa mudah atau sulit
                          Anda dalam menyelesaikan tugas?
                        </div>
                        <v-slider
                          :ticks="tickLabels"
                          class="slider-score"
                          :max="10"
                          step="1"
                          show-ticks="always"
                          tick-size="4"
                        ></v-slider>
                        <v-textarea
                          class="saran"
                          label="Kritik dan Saran"
                          variant="solo"
                        ></v-textarea>
                        <v-btn variant="tonal" color="blue" @click="simpanObj1()"> Simpan </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="9">
                    <div style="--aspect-ratio: 16/9;">
                      <iframe
                        src="https://satudata.jakarta.go.id/home"
                        width="1600"
                        height="900"
                        frameborder="0"
                      >
                      </iframe>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>

              <v-window-item value="two">
                <v-row>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12">
                        <v-expansion-panels>
                          <v-expansion-panel
                            title="Misi 2 (Klik untuk melihat misi)"
                            text="Anda adalah seorang mahasiswa yang sedang mencari ‘dashboard’ mengenai ‘Kemacetan di DKI Jakarta’. Bagaimana Anda melakukannya?"
                          ></v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <v-col cols="12" style="margin-top: 1em;">
                        <div class="text-caption">
                          Dalam skala 1 sampai 10, seberapa mudah atau sulit
                          Anda dalam menyelesaikan tugas?
                        </div>
                        <v-slider
                          :ticks="tickLabels"
                          class="slider-score"
                          :max="10"
                          step="1"
                          show-ticks="always"
                          tick-size="4"
                        ></v-slider>
                        <v-textarea
                          class="saran"
                          label="Kritik dan Saran"
                          variant="solo"
                        ></v-textarea>
                        <v-btn variant="tonal" color="blue" @click="simpanObj2()"> Simpan </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="9">
                    <div style="--aspect-ratio: 16/9;">
                      <iframe
                        src="https://satudata.jakarta.go.id/home"
                        width="1600"
                        height="900"
                        frameborder="0"
                      >
                      </iframe>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>

              <v-window-item value="three">
                <v-row>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12">
                        <v-expansion-panels>
                          <v-expansion-panel
                            title="Misi 3 (Klik untuk melihat misi)"
                            text="Masuk ke halaman 'Pencarian' atau 'Search'. Anda sedang mencari semua informasi dari Organisasi “Dinas Komunikasi Informasi dan Statistik”. Bagaimana Anda melakukannya?"
                          ></v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <v-col cols="12" style="margin-top: 1em;">
                        <div class="text-caption">
                          Dalam skala 1 sampai 10, seberapa mudah atau sulit
                          Anda dalam menyelesaikan tugas?
                        </div>
                        <v-slider
                          :ticks="tickLabels"
                          class="slider-score"
                          :max="10"
                          step="1"
                          show-ticks="always"
                          tick-size="4"
                        ></v-slider>
                        <v-textarea
                          class="saran"
                          label="Kritik dan Saran"
                          variant="solo"
                        ></v-textarea>
                        <v-btn variant="tonal" color="blue" @click="simpanObj3()"> Simpan </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="9">
                    <div style="--aspect-ratio: 16/9;">
                      <iframe
                        src="https://satudata.jakarta.go.id/home"
                        width="1600"
                        height="900"
                        frameborder="0"
                      >
                      </iframe>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>

              <v-window-item value="four">
                <v-row>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12">
                        <v-expansion-panels>
                          <v-expansion-panel
                            title="Misi 4 (Klik untuk melihat misi)"
                            text="Masuk ke halaman 'Pencarian' atau 'Search'. Anda sedang mencari semua informasi mengenai topik ‘Perekonomian’ di DKI Jakarta. Bagaimana Anda melakukannya?"
                          ></v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <v-col cols="12" style="margin-top: 1em;">
                        <div class="text-caption">
                          Dalam skala 1 sampai 10, seberapa mudah atau sulit
                          Anda dalam menyelesaikan tugas?
                        </div>
                        <v-slider
                          :ticks="tickLabels"
                          class="slider-score"
                          :max="10"
                          step="1"
                          show-ticks="always"
                          tick-size="4"
                        ></v-slider>
                        <v-textarea
                          class="saran"
                          label="Kritik dan Saran"
                          variant="solo"
                        ></v-textarea>
                        <v-btn variant="tonal" color="blue" @click="simpanObj4()"> Simpan </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="9">
                    <div style="--aspect-ratio: 16/9;">
                      <iframe
                        src="https://satudata.jakarta.go.id/home"
                        width="1600"
                        height="900"
                        frameborder="0"
                      >
                      </iframe>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>

              <v-window-item value="five">
                <v-row>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12">
                        <v-expansion-panels>
                          <v-expansion-panel
                            title="Misi 5 (Klik untuk melihat misi)"
                            text="Masuk ke halaman 'Pencarian' atau 'Search'. Anda sedang mencari Buku berjudul ‘Data Statistik Sektoral Pemerintah Provinsi DKI Jakarta 2022’. Bagaimana Anda melakukannya?"
                          ></v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <v-col cols="12" style="margin-top: 1em;">
                        <div class="text-caption">
                          Dalam skala 1 sampai 10, seberapa mudah atau sulit
                          Anda dalam menyelesaikan tugas?
                        </div>
                        <v-slider
                          :ticks="tickLabels"
                          class="slider-score"
                          :max="10"
                          step="1"
                          show-ticks="always"
                          tick-size="4"
                        ></v-slider>
                        <v-textarea
                          class="saran"
                          label="Kritik dan Saran"
                          variant="solo"
                        ></v-textarea>
                        <v-btn variant="tonal" color="blue" @click="simpanObj5()"> Simpan </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="9">
                    <div style="--aspect-ratio: 16/9;">
                      <iframe
                        src="https://satudata.jakarta.go.id/home"
                        width="1600"
                        height="900"
                        frameborder="0"
                      >
                      </iframe>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.icon-cehck{
  margin-left: 10px;
}
.biodata{
  margin-bottom: 30px;
}
.notes-record{
  font-size: 10px;
  margin-top: 5px;
  padding-bottom: 5px;
  color: red;
}
.preview-recording{
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.slider-score{
  font-size: 10px;
}
.saran{
  font-size: 10px;
}
</style>

<script>

export default {
  name: 'HelloWorld',
  data() {
    return {
      tab: null,
      objective: this.$store.state.objective,
      data:[],
      btnCapturelayar:true,
      btnRekamlayar:false,
      btnBerhentirekamlayar:false,
      displayMediaOptions: {
        video: {
          displaySurface: "window",
          cursor: "always"
        },
        audio: true,
      },
      chunks: [],
      isRecording: false,
      mediaRecorder: [],
      tickLabels: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10'
      }
    }
  },
  methods: {
    updateOptions() {
        this.displayMediaOptions.audio = true;
        this.displayMediaOptions.video.cursor = "always";
    },
    async startCapture() {
      this.updateOptions();
      const videoElem = document.getElementById("vpreview");
      try {
        videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia(
          this.displayMediaOptions
        );
        this.isCapture = true;
        this.btnCapturelayar = false;
        this.btnRekamlayar = true;
      } catch (err) {
        console.error(err);
      }
    },
    startRecord() {
      if (!this.isRecording) {
        const videoElem = document.getElementById("vpreview")
        this.mediaRecorder = new MediaRecorder(videoElem.srcObject)
        this.mediaRecorder.start()
        this.btnRekamlayar = false
        this.btnBerhentirekamlayar = true
        var t = this
        this.mediaRecorder.ondataavailable = function(e) {
          t.chunks.push(e.data)
        }

        this.mediaRecorder.onstop = function() {
        var blob = new Blob(t.chunks, { 'type' : 'video/mp4; codecs=mpeg4,vorbis' })
          t.chunks = []
          t.videoUrl = window.URL.createObjectURL(blob)
        }
        this.isRecording = true
        this.btnCapturelayar = false
        this.btnRekamlayar = false
        this.btnBerhentirekamlayar = true

        if(this.videoUrl){
          const link = document.createElement("a");
          link.href = this.videoUrl;
          link.download = 'video-download';
          link.click();

          this.isRecording = false
          this.btnCapturelayar = true
          this.btnRekamlayar = false
          this.btnBerhentirekamlayar = false
        }

      }
      else {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    },
    simpanNama(){
      if(this.data.fullname){
        localStorage.setItem('bio', this.data.fullname)
        this.$store.commit('bio', true)
      } else {
        localStorage.setItem('bio', this.data.fullname)
        this.$store.commit('bio', false)
      }
    },
    simpanObj1(){
      localStorage.setItem('obj1', '10')
      this.$store.commit('obj1', true)
    },
    simpanObj2(){
      localStorage.setItem('obj2', '10')
      this.$store.commit('obj2', true)
    },
    simpanObj3(){
      localStorage.setItem('obj3', '10')
      this.$store.commit('obj3', true)
    },
    simpanObj4(){
      localStorage.setItem('obj4', '10')
      this.$store.commit('obj4', true)
    },
    simpanObj5(){
      localStorage.setItem('obj5', '10')
      this.$store.commit('obj5', true)
    }
  }
}
</script>
