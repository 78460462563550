<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {

    }
  },
  mounted() {
    document.onkeydown = function (e) {
      if (e.keyCode === 116) {
        return false;
      }
    }
  }
}
</script>
